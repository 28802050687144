import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Tooltip,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { setProposalData } from "../../../../modules/proposal.slice";
import { useUploadCignaDocMutation } from "../../../../services/CKYC.service";
import { useUpdateProposalFormData } from "../../../../services/Proposal.service";
import * as yup from "yup";
import Select from "../InputComponents/Select";
import TextInput from "../InputComponents/TextInput";
import { Check, Close } from "@mui/icons-material";

const addressProofOptions = [
  {
    OPTION_KEY: "PASSPORT",
    OPTION_VAL: "Passport",
  },
  {
    OPTION_KEY: "VOTEID",
    OPTION_VAL: "Voter Id",
  },
  {
    OPTION_KEY: "DLICESSE",
    OPTION_VAL: "Driving License",
  },
  {
    OPTION_KEY: "AADHARCARD",
    OPTION_VAL: " Card",
  },

  {
    OPTION_KEY: "PHONEBILL",
    OPTION_VAL: "Recent Telephone Bill",
  },
  {
    OPTION_KEY: "BANKPASSBOOK",
    OPTION_VAL: "Bank Passbook or Statement",
  },
  {
    OPTION_KEY: "UTILITYBILL",
    OPTION_VAL: "Utility Bills",
  },
  {
    OPTION_KEY: "ACCOMODATIONLETTER",
    OPTION_VAL: "Accomodation allotment letter from Govt. Dept.",
  },
  {
    OPTION_KEY: "PROPERTYTAX",
    OPTION_VAL: "Property or Municipal tax receipt",
  },
];
const identityProofOptions = [
  {
    OPTION_KEY: "PANCARD",
    OPTION_VAL: "Pan Card",
  },
  {
    OPTION_KEY: "PASSPORT",
    OPTION_VAL: "Passport",
  },
  {
    OPTION_KEY: "VOTEID",
    OPTION_VAL: "Voter Id",
  },
  {
    OPTION_KEY: "DLICESSE",
    OPTION_VAL: "Driving License",
  },
  {
    OPTION_KEY: "JOBCARD",
    OPTION_VAL: "Job Card issued by NREGA",
  },
  {
    OPTION_KEY: "LETTERHEAD",
    OPTION_VAL: "Letter Issued by National Population Register",
  },
  {
    OPTION_KEY: "GAZETTEOFFICER",
    OPTION_VAL:
      "Letter from Gazetted Officer with duly attested photograph of customer",
  },
  {
    OPTION_KEY: "AADHARCARD",
    OPTION_VAL: "Aadhar Card",
  },
];

const CKYCCignaForm = () => {
  const { saveForm } = useUpdateProposalFormData();
  const dispatch = useDispatch();
  const { activeProposal, proposalData } = useTypedSelector(
    (state) => state.proposal
  );
  const proposer_details = proposalData?.proposer_details;
  const ckyc_details = proposalData?.ckyc_details;
  const { upload } = useUploadCignaDocMutation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleUploadFiles = async (values) => {
    try {
      const proposer_address_proof = values?.proposer_address_proof;
      const proposer_identity_proof = values?.proposer_identity_proof;
      const proposer_photo = values?.proposer_photo;

      const uploadResponse = await upload({
        proposer_address_proof,
        proposer_identity_proof,
        proposer_photo,
      });

      const { return_data } = uploadResponse;

      // dispatch(
      //   setProposalData({
      //     ...proposalData,
      //     ckyc_details: {
      //       ...proposalData?.ckyc_details,
      //       ...values,
      //       ...return_data,
      //     },
      //   })
      // );

      return return_data;
    } catch (error) {
      console.error("Upload error:", error);
      throw error;
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          address_document_type: ckyc_details?.address_document_type || "",
          identity_document_type: ckyc_details?.identity_document_type || "",
          proposer_address_proof: ckyc_details?.proposer_address_path || "",
          proposer_identity_proof: ckyc_details?.proposer_identity_path || "",
          proposer_address_proof_url: "",
          proposer_identity_proof_url: "",
          proposer_photo: ckyc_details?.proposer_photo_path || "",
          proposer_photo_url: "",
        }}
        validationSchema={yup.object().shape({
          address_document_type: yup
            .string()
            .required("This Field is required"),
          identity_document_type: yup
            .string()
            .required("This Field is required"),
          // proposer_address_proof: yup
          //   .mixed()
          //   .required()
          //   .test(
          //     "address_proof_doc",
          //     "Max Size Limit is 5MB",
          //     (val) => val && val?.size / 1024 / 1024 <= 5
          //   ),
          // proposer_identity_proof: yup
          //   .mixed()
          //   .required()
          //   .test(
          //     "proposer_photo",
          //     "Max Size Limit is 5MB",
          //     (val) => val && val?.size / 1024 / 1024 <= 5
          //   ),
          // proposer_photo: yup
          //   .mixed()
          //   .required()
          //   .test(
          //     "proposer_photo",
          //     "Max Size Limit is 5MB",
          //     (val) => val && val?.size / 1024 / 1024 <= 5
          //   ),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const uploadResponse = await handleUploadFiles(values);

            await saveForm({
              ckyc_details: {
                ...ckyc_details,
                ...values,
                ...uploadResponse,
              },
              from_screen: "ckyc",
            });

            console.log("Form submission successful");
          } catch (error) {
            console.error("Submission error:", error);
          } finally {
            setSubmitting(false); // Uncomment this if you want to update the form's submitting state.
          }
          dispatch(
            setProposalData({
              ...proposalData,
              ckyc_details: {
                ...proposalData?.ckyc_details,
                ...values,
              },
            })
          );
        }}
      >
        {({ handleChange, handleBlur, setFieldValue, values, submitForm }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Select
                  name="address_document_type"
                  options={addressProofOptions}
                  label="Address Proof"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {values?.proposer_address_proof ? (
                  <Box display="flex" flexDirection="column">
                    <Typography
                      fontSize="16px"
                      fontWeight="500"
                      marginBottom="8px"
                    >
                      View Address Document
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      padding="1px"
                      border="1px solid #ccc"
                      borderRadius="4px"
                    >
                      <Box
                        flexGrow={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        padding="4px"
                      >
                        <a
                          href={
                            // ckyc_details.proposer_address_path
                            //   ? ckyc_details.proposer_address_path
                            //   : values.proposer_address_proof_url
                            ckyc_details.proposer_address_path ||
                            values.proposer_address_proof_url
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {values.proposer_address_proof.name ||
                            "View Uploaded Address Proof"}
                        </a>
                      </Box>
                      <Tooltip title="Clear">
                        <IconButton
                          onClick={() => {
                            URL.revokeObjectURL(
                              values.proposer_address_proof_url,
                              ""
                            );
                            setFieldValue("proposer_address_proof", "");
                            setFieldValue("proposer_address_proof_url", "");
                          }}
                          aria-label="clear"
                        >
                          <Close />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                ) : (
                  <TextInput
                    type="file"
                    name="proposer_address_proof"
                    label="Upload Address Proof Document"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        setFieldValue("proposer_address_proof", file);
                        setFieldValue(
                          "proposer_address_proof_url",
                          URL.createObjectURL(file)
                        );
                      }
                    }}
                  />
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <Select
                  name="identity_document_type"
                  options={identityProofOptions}
                  label="Identity Proof"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {values.proposer_identity_proof ? (
                  <Box display="flex" flexDirection="column">
                    <Typography
                      fontSize="16px"
                      fontWeight="500"
                      marginBottom="8px"
                    >
                      View identity Document
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      padding="1px"
                      border="1px solid #ccc"
                      borderRadius="4px"
                    >
                      <Box
                        flexGrow={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        padding="4px"
                      >
                        <a
                          href={
                            ckyc_details.proposer_identity_path ||
                            values.proposer_identity_proof_url
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {values.proposer_identity_proof.name ||
                            "View Uploaded identity Proof"}
                        </a>
                      </Box>
                      <Tooltip title="Clear">
                        <IconButton
                          onClick={() => {
                            URL.revokeObjectURL(
                              values?.proposer_identity_proof_url,
                              ""
                            );
                            setFieldValue("proposer_identity_proof_url", "");
                            setFieldValue("proposer_identity_proof", "");
                          }}
                          aria-label="clear"
                        >
                          <Close />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                ) : (
                  <TextInput
                    type="file"
                    name="proposer_identity_proof"
                    label="Upload Identity Proof Document"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        setFieldValue("proposer_identity_proof", file);
                        setFieldValue(
                          "proposer_identity_proof_url",
                          URL.createObjectURL(file)
                        );
                      }
                    }}
                  />
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                {values?.proposer_photo ? (
                  <Box display="flex" flexDirection="column">
                    <Typography
                      fontSize="16px"
                      fontWeight="500"
                      marginBottom="8px"
                    >
                      View Photo
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      padding="1px"
                      border="1px solid #ccc"
                      borderRadius="4px"
                    >
                      <Box
                        flexGrow={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        padding="4px"
                      >
                        <a
                          href={
                            // ckyc_details.proposer_photo_path
                            //   ? ckyc_details.proposer_photo_path
                            //   : values.proposer_photo_url
                            ckyc_details.proposer_photo_path ||
                            values.proposer_photo_url
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {values.proposer_photo.name ||
                            "View Uploaded Address Proof"}
                        </a>
                      </Box>
                      <Tooltip title="Clear">
                        <IconButton
                          onClick={() => {
                            URL.revokeObjectURL(values.proposer_photo_url, "");
                            setFieldValue("proposer_photo", "");
                            setFieldValue("proposer_photo_url", "");
                          }}
                          aria-label="clear"
                        >
                          <Close />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                ) : (
                  <TextInput
                    type="file"
                    name="proposer_photo"
                    label="Upload Address Proof Document"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        setFieldValue("proposer_photo", file);
                        setFieldValue(
                          "proposer_photo_url",
                          URL.createObjectURL(file)
                        );
                      }
                    }}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                display={"flex"}
                my={1}
              >
                <Box sx={{ position: "relative" }}>
                  <Button
                    size={isMobile ? "medium" : "large"}
                    variant="contained"
                    onClick={submitForm}
                  >
                    Proceed to Insurer Details
                  </Button>
                  {false && (
                    <CircularProgress
                      size={isMobile ? 18 : 24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CKYCCignaForm;
